import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ddi-field"
export default class extends Controller {
  static targets = ['orig', 'country', 'number', 'dnis']


  update_number () {
    let local = this.origTarget.value
    if (this.origTarget.value.charAt(0) === '0' && this.origTarget.value.charAt(1) !== '0') {
      local = this.origTarget.value.substring(1)
    }
    this.numberTarget.value = this.countryTarget.value + local
    this.dnisTarget.value = this.numberTarget.value
  }
}
